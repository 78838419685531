
import 'foundation-sites'

import $ from 'jquery';
window.$ = $;


console.log('Vite ⚡️ Rails')


// initialize the page
window.addEventListener('load', (event) => {
    initPage();
});

window.addEventListener('turbo:render', (event) => {
    initPage();
});
function initPage() {
    $(document).foundation();
    console.log('foundation ready');
}